import { useState } from "react";
import SectionTitle from "../components/SectionTitle";
import { useTheme } from "../contexts/ThemeContext";
import "../styles/pricing-section.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useResponsiveView from "../hooks/useResponsiveView";
import { useCurrency } from "../contexts/CurrencyContext";
import { Switch } from "@mui/material";
import { MaterialUISwitch } from "../components/CustomMUIInputs";
import eventBus from "../eventBus";
const Plans = [
    {
        name : "Essential Plans",
        pricing : {
            'USD' : {
                monthly : 25,
                yearly : 16
            },
            'INR' : {
                monthly : 2000,
                yearly : 1375
            }
        },
        section:'s',
        features : {
            monthly : [
                "✔ Designed for small teams",
                "✔ Manage upto 15 open jobs",
                "✔ Process upto 50 active resumes",
                "✔ Extra free credits"
            ],
            yearly : [
                "✔ Designed for small teams",
                "✔ Manage upto 15 open jobs",
                "✔ Process upto 50 active resumes",
                "✔ Extra free credits"
            ],
        },
        s : {
            name : "Basic",
            pricing : {
                'USD' : {
                    monthly : 25,
                    yearly : 16
                },
                'INR' : {
                    monthly : 2000,
                    yearly : 1375
                }
            },
            section:'s',
            features : {
                monthly : [
                    "Up to 5 open jobs",
                    "Up to 30 active resumes",
                    "Single user",
                    "150 free credits"
                ],
                yearly : [
                    "Up to 5 open jobs",
                    "Up to 30 active resumes",
                    "Up to 5 users",
                    "1k free credits"
                ]
            }    
        },
        r : {
            name : "Professional",
            pricing : {
                'USD' : {
                    monthly : 80,
                    yearly : 67
                },
                'INR' : {
                    monthly : 6600,
                    yearly : 5415
                }
            },
            section : 'r',
            features : {
                monthly : [
                    "Up to 50 open jobs",
                    "Up to 500 active resumes",
                    "Up to 5 users",
                    "5k free credits"
                ],
                yearly : [
                    "Up to 50 open jobs",
                    "Up to 500 active resumes",
                    "Up to 15 users",
                    "Unlimited credits"
                ],
            }
        },
        root : {
            name : "Essential Plans",
            pricing : {
                'USD' : {
                    monthly : 25,
                    yearly : 16
                },
                'INR' : {
                    monthly : 2000,
                    yearly : 1375
                }
            },
            section:'s',
            features : {
                monthly : [
                    "✔ Designed for small teams",
                    "✔ Manage upto 15 open jobs",
                    "✔ Process upto 50 active resumes",
                    "✔ Extra free credits"
                ],
                yearly : [
                    "✔ Designed for small teams",
                    "✔ Manage upto 15 open jobs",
                    "✔ Process upto 50 active resumes",
                    "✔ Extra free credits"
                ],
            },
        }
    },
    {
        name : "Premium plans",
        pricing : {
            'USD' : {
                monthly : 80,
                yearly : 67
            },
            'INR' : {
                monthly : 6600,
                yearly : 5415
            }
        },
        section : 'r',
        features : {
            monthly : [
                "✔ Designed for Recruiter agencies",
                "✔ Manage upto 100 open jobs",
                "✔ Process upto 1k active resumes",
                "✔ Extra free credits"
            ],
            yearly : [
                "✔ Designed for Recruiter agencies",
                "✔ Manage upto 100 open jobs",
                "✔ Process upto 1k active resumes",
                "✔ Unlimited credits"
            ],
        },
        s : {
            name : "Advance",
            pricing : {
                'USD' : {
                    monthly : 40,
                    yearly : 30
                },
                'INR' : {
                    monthly : 3300,
                    yearly : 2415
                }
            },
            section:'s',
            features : {
                monthly : [
                    "Up to 15 open jobs",
                    "Up to 50 active resumes",
                    "Up to 3 users",
                    "250 free credits"
                ],
                yearly : [
                    "Up to 15 open jobs",
                    "Up to 50 active resumes",
                    "Up to 10 users",
                    "2k free credits"
                ]
            },
        },
        r  : {
            name : "Elite",
            pricing : {
                'USD' : {
                    monthly : 110,
                    yearly : 100
                },
                'INR' : {
                    monthly : 9200,
                    yearly : 8350
                }
            },
            section : 'r',
            features : {
                monthly : [
                    "Up to 100 open jobs",
                    "Up to 1k active resumes",
                    "Up to 8 users",
                    "10k free credits"
                ],
                yearly : [
                    "Up to 100 open jobs",
                    "Up to 1k active resumes",
                    "Up to 30 users",
                    "Unlimited credits"
                ],

            }
        },
        root :{ 
            name : "Premium plans",
            pricing : {
                'USD' : {
                    monthly : 80,
                    yearly : 67
                },
                'INR' : {
                    monthly : 6600,
                    yearly : 5415
                }
            },
            section : 'r',
            features : {
                monthly : [
                    "✔ Designed for Recruiter agencies",
                    "✔ Manage upto 100 open jobs",
                    "✔ Process upto 1k active resumes",
                    "✔ Extra free credits"
                ],
                yearly : [
                    "✔ Designed for Recruiter agencies",
                    "✔ Manage upto 100 open jobs",
                    "✔ Process upto 1k active resumes",
                    "✔ Unlimited credits"
                ],
            }
        }
    }
]

const PricingSection = ()=>{
    const { theme } = useTheme();
    const [plan, setPlan] = useState('m');
    const [planSection, setPlanSection] = useState('root');
    const [flipped, setFlipped] = useState(false);
    const isMobile = useResponsiveView();
    const { currency, changeCurrency } = useCurrency();

    return (
        <section className="pricing-section" id="pricing-section" onClick={(e)=>{
            setFlipped(false);
            setTimeout(()=>{
                setPlanSection('root');
            }, 250)
        }}>
            <SectionTitle title={"Get Ongoing Access with Easy Payments!"} />
            <div className="pricing-box">
                <div className="pricing-box-header">
                    <div className="pricing-toggle"></div>
                    <div className={`pricing-chip ${theme}`}>
                        <div className={`pricing-chip-option ${plan === 'm' ? 'active' : null}`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setPlan('m');
                            }}
                        >Monthly</div>
                        <div className={`pricing-chip-option ${plan === 'y' ? 'active' : null}`}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setPlan('y');
                            }}
                        >Yearly 
                            <div className={`pricing-chip-suboption ${theme}`}>
                                Save up to 30%
                            </div>
                        </div>
                    </div>

                    <div className="pricing-toggle">
                        <MaterialUISwitch onthumb={"usd.svg"} offthumb={"inr.svg"} trackbg={theme === 'dark' ? 'rgba(217, 217, 217, 0.2)' : 'rgba(105, 105, 105, 0.2)'} 
                            checked={currency === "USD"}
                            onClick={(e)=>e.stopPropagation()}
                            onChange={(event, checked)=>changeCurrency(checked ? 'USD' : 'INR')}
                        />
                    </div>
                </div>

                <div className="pricing-plans">
                    <div className={`pricing-plans-card ${theme}`}>
                        <div className={`front ${flipped ? 'flipped' : null}`}>
                            <h3 className="pricing-card-name">Free</h3>
                            <div className="pricing-card-description">
                                Get started with Free plan to explore our service
                            </div>
                            <div className="pricing-card-price">{currency === 'INR' ? '₹' : '$'}0<span>/30 days</span></div>
                            <div className="pricing-card-feature">Up to 10 open jobs</div>
                            <div className="pricing-card-feature">Up to 100 active resumes</div>
                            <div className="pricing-card-feature">Up to 5 users</div>
                            <div className="pricing-button" onClick={()=>{
                                eventBus.dispatchEvent(new CustomEvent('planSelected', {detail : 'Free'}));
                                document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                            }}>Get Started</div>
                        </div>
                        <div className={`back no-border ${flipped ? 'flipped' : null}`}>
                            <div
                                onClick={()=>{
                                    setFlipped(false);
                                    setTimeout(()=>{
                                        setPlanSection('root');
                                    }, 250)
                                }}
                                style={{
                                    cursor:"pointer"
                                }}
                            ><ArrowBackIosIcon fontSize="large" /></div>
                        </div>
                    </div>
                    {
                        Plans.map((item, index)=>{
                            return <div key={index} className={`pricing-plans-card ${theme}`} onClick={(e)=>e.stopPropagation()}>
                                <div className={`front ${flipped ? 'flipped' : null}`}>
                                    <h3 className="pricing-card-name">{item.name}</h3>
                                    <div className="pricing-card-price"><span>Starting At</span> <br /> {currency === 'INR' ? '₹' : '$'}{(item[planSection].pricing[currency][plan === 'm' ? 'monthly' : 'yearly'])?.toLocaleString()}<span>/month</span></div>
                                    <br />
                                    {item.features[plan === 'm' ? 'monthly' : 'yearly'].map((ftr, idx)=>(
                                        <div key={idx} className="pricing-card-feature">{ftr}</div>
                                    ))}
                                    <div className="pricing-button-explore" onClick={(event)=>{
                                        event.stopPropagation()
                                        setFlipped(true);
                                        setTimeout(()=>{
                                            setPlanSection(item.section);
                                        }, 250)
                                        // document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                                    }}>Explore Similar Plans <ArrowRightAltIcon style={{height:"45%"}} /> </div>
                                </div>
                                <div className={`back ${flipped ? 'flipped' : null}`}>
                                <h3 className="pricing-card-name">{item[planSection]?.name}</h3>
                                    <div className="pricing-card-price">{currency === 'INR' ? '₹' : '$'}{(item[planSection].pricing[currency][plan === 'm' ? 'monthly' : 'yearly'])?.toLocaleString()}<span>/month</span></div>
                                    {item[planSection]?.features[plan === 'm' ? 'monthly' : 'yearly'].map((ftr, idx)=>(
                                        <div key={idx} className="pricing-card-feature">{ftr}</div>
                                    ))}
                                    <div className="pricing-button" onClick={()=>{
                                        eventBus.dispatchEvent(new CustomEvent('planSelected', {detail : item[planSection]?.name}));
                                        document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                                    }}>Get Started</div>
                                </div>
                            </div>
                        })
                    }
                    <div className={`pricing-plans-card ${theme}`}>
                        <div className={`front ${flipped ? 'flipped' : null}`}>
                            <h3 className="pricing-card-name">Enterprise Plan</h3>
                            <div className="pricing-card-description">
                                To get a customized plan best suitable for you
                            </div>
                            <div className="pricing-card-price" >Talk to Sales</div>
                            <div className="pricing-card-feature">Fully customizable</div>
                            <div className="pricing-card-feature">+ Additional features</div>
                            <div className="pricing-card-feature">+ Flexible & Affordable</div>
                            <div className="pricing-button" onClick={()=>{
                                eventBus.dispatchEvent(new CustomEvent('planSelected', {detail : 'Enterprise'}));
                                document.getElementById("contact-us-section").scrollIntoView({behavior:"smooth"})
                            }}>Get Started</div>
                        </div>
                        <div className={`back no-border ${flipped ? 'flipped' : null}`}>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection;