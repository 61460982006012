import { useTheme } from "../contexts/ThemeContext";
import SectionTitle from "../components/SectionTitle";
import "../styles/contact-us-section.css";
import FillButton from "../components/FillButton";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Collapse, TextField } from "@mui/material";
import { CustomCheckbox, CustomSelect } from "../components/CustomInputs";
import { toast } from "react-toastify";
import { CustomAutoComplete, CustomTextField } from "../components/CustomMUIInputs";
import { color } from "framer-motion";
import eventBus from "../eventBus";

const ContactUsSection = ()=>{
    const { theme } = useTheme();
    const formRef = useRef();
    const [industry, setIndustry] = useState('');
    const [interestedPlan, setInterestedPlan] = useState("");
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const formData = new FormData(formRef.current);
        const formJson = Object.fromEntries(formData.entries());
        let errors = {};
    
        if (!formJson.company_name) errors.company_name = "Company Name is required";
        if (!industry) errors.industry = "Industry is required";
        if (!formJson.company_size) errors.company_size = "Company Size is required";
        if (!formJson.full_name) errors.full_name = "Full Name is required";
        if (!formJson.job_title) errors.job_title = "Job Title is required";
        if (!formJson.email_id || !/\S+@\S+\.\S+/.test(formJson.email_id)) errors.email_id = "Valid Email ID is required";
        if (!formJson.phone_number || !/^\d{10}$/.test(formJson.phone_number)) errors.phone_number = "Valid Phone Number is required (10 digits)";
    
        return errors;
    };

    const handleSubmit = (event)=>{
        event.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        
        const formData = new FormData(formRef.current);
        const formJson = Object.fromEntries(formData.entries());
        fetch("/contact-us", {
            headers: {
                'Content-Type' : "application/json"
            },
            method:"post",
            body:JSON.stringify({...formJson, interested_plan:interestedPlan, industry})
        })
        .then(
            (response)=>{
                if(response.status === 200){
                    console.log(formJson);
                    toast.success("Form Submitted", {closeOnClick:true});
                    formRef.current.reset(); 
                    setIndustry("");
                    setInterestedPlan("");
                    setErrors({});
                }else{
                    response.json().then(data=>{
                        console.log(data);
                    })
                }
            }
        )
        .catch(error=>{
            console.log(error);
        }) 
    }

    useEffect(()=>{
        const handlePlanSelected = (e)=>{
            console.log(e.detail)
            setInterestedPlan(e.detail || "");
        }

        eventBus.addEventListener('planSelected', handlePlanSelected);
        return ()=>eventBus.removeEventListener('planSelected', handlePlanSelected);
    }, [])

    return (
        <section className={`contact-us-section ${theme}`}>
            <SectionTitle title={"Transform Your Recruitment Today!"} />
            <div className="contact-us-desc">
            Unlock the power of AI and Machine Learning to speed up your hiring process and discover top talent more efficiently.
            </div>
            <form className="contact-us-form" ref={formRef} id="contact-us-section" onSubmit={handleSubmit}>
                <div className="contact-us-form-section">
                    <h3>Company Information</h3>
                    <div className="contact-us-form-subsection">
                        <CustomTextField
                            name="company_name"
                            id={"companyName"}
                            placeholder="Enter Company Name"
                            type="text"
                            label={"Company Name"}
                            required
                        />
                        <CustomAutoComplete
                            id="industry"
                            name="industry"
                            options={["Real Estate", "Education"]}
                            placeholder="Select Industry"
                            label="Industry"
                            freeSolo
                            disableClearable
                            inputValue={industry}
                            onInputChange={(e, new_value)=>setIndustry(new_value)}
                            required
                        />
                        <CustomTextField
                            name="company_size"
                            id={"companySize"}
                            label={"Company Size"}
                            placeholder="Select Company Size" 
                            type="text"
                            required
                        />
                    </div>
                </div>
                
                <div className="contact-us-form-section">
                    <h3>Contact Information</h3>
                    <div className="contact-us-form-subsection contact-info">
                        <CustomTextField
                            name="full_name"
                            id={"fullName"}
                            placeholder="Enter Full Name"
                            label={"Full Name"}
                            type="text"
                            required
                        />
                        <CustomTextField
                            name="job_title"
                            id={"jobTitle"}
                            placeholder="Enter Job Title"
                            label={"Job Title"} 
                            type="text"
                            required
                        />
                        <CustomTextField
                            name="email_id"
                            id={"emailID"}
                            placeholder="Enter Email ID"
                            label={"Email ID"} 
                            type="email"
                            required
                        />
                        <CustomTextField
                            name="phone_number"
                            id={"Phone Number"}
                            placeholder="Enter Phone Number"
                            label={"Phone Number"} 
                            type="text"
                            error={!!errors.phone_number}
                            helperText={errors.phone_number}
                            required
                        />
                    </div>
                </div>
                
                <div className="contact-us-form-section">
                    <h3>Additional Information</h3>
                    <div className="contact-us-form-subsection additional-information">
                        <CustomAutoComplete
                            id="interested_plan"
                            name="interested_plan"
                            options={['Free', 'Basic', 'Advance', 'Professional', 'Elite', 'Enterprise']}
                            placeholder="Select Plan"
                            label="Interested Plan"
                            disableClearable
                            freeSolo={false}
                            value={interestedPlan}
                            onInputChange={(e, new_value)=>{
                                if(['Free', 'Basic', 'Advance', 'Professional', 'Elite', 'Enterprise'].includes(new_value)){
                                    setInterestedPlan(new_value);
                                }else{
                                    setInterestedPlan("");
                                }
                            }}
                            required
                        />
                    </div>
                </div>

                <div className="contact-us-form-section">
                    <div className="contact-us-form-subsection submit">
                        <FillButton className={`submit-button ${theme}`} inverse={true} value={"Submit"}/>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default ContactUsSection;